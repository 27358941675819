<template>
  <div class="detail-top">
    <van-nav-bar
      :title="headerTitle"
      left-arrow
      @click-left="onClickLeft"
    />

    <div class="content-wrap">
      <div class="title">{{title}}</div>
      <div class="time-wrap">
        <div class="time">申请时间：{{ createTime }}</div>
        <!-- 切换协议/失败重发等等 -->
        <slot></slot>
      </div>
    </div>
    <slot name="van-top"></slot>
    <van-tabs :type="tabsType" v-model="active" @click="handleTabs">
      <van-tab v-for="(item, index) in tabsList" :key="index" :title="item.title" :name="item.key"></van-tab>
    </van-tabs>
  </div>
</template>

<script>
import Vue from 'vue';
import { Tab, Tabs } from 'vant';

Vue.use(Tab).use(Tabs);
export default {
  name: 'DetailTop',
  props: {
    cutName: {
      type: String,
      require: true,
    },
    tabsList: {
      type: Array,
      require: true,
    },
    title: {
      type: String,
      require: true,
    },
    createTime: {
      type: String,
      require: true,
    },
    headerTitle: {
      type: String,
      require: true,
    },
    tabsType: {
      type: String,
      default: 'line',
    }
  },
  data(){
    return {
      active: '',
    }
  },
  watch: {
    tabsList: {
      handler(){
        this.active = '1'
        this.handleTabs('1')
      },
      deep: true,
    }
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1)
    },
    handleTabs(e){
      document.getElementsByName(e)[0].scrollIntoView()
    }
  }
}
</script>

<style lang="scss" scoped>
.detail-top{
  position: sticky;
  top: 0;
  z-index: 9999;
  background: #fff;
  .content-wrap{
    background-color: #fff;
    padding: 14px;
    .title{
      font-size: 18px;
      font-weight: 600;
      height: 34px;
      line-height: 34px;
    }
    .time-wrap{
      display: flex;
      justify-content: space-between;
      .time{
        color: $gray;
      }
      .cut{
        color: $main;
      }
    }
  }
}
</style>